import { environment } from 'src/environments/environment';
import { Lead } from './lead.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  lead: Lead;

  constructor(
    private http: HttpClient
  ) {
    this.lead = {
      firstName: window.localStorage.getItem('firstName') || '',
      lastName: window.localStorage.getItem('lastName') || '',
      email: window.localStorage.getItem('email') || '',
      companyName: window.localStorage.getItem('companyName') || ''
    };
  }

  createLead(lead: Lead) {
    //redo later
    this.lead = lead;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    window.localStorage.setItem('firstName', lead.firstName);
    window.localStorage.setItem('lastName', lead.lastName);
    window.localStorage.setItem('email', lead.email);
    window.localStorage.setItem('companyName', lead.companyName);
    return this.http.post(`${environment.apiUrl}/sendLead`, lead, httpOptions);
  }

  getLead(): Lead {
    return this.lead;
  }
}
