<div class="page">
  <section class="full-size">
    <div class="content">
      <p class="mat-display-1 mb-0"><strong class="color--primary">HCH GROUP</strong> is a full service General Contractor in both Commercial and Residential projects. With a background in Multi-Family our team has always focused in specializing in creative and effective solutions for Housing. We take pride in our product and believe that building a quality product is KEY. It must stands the test of time both in workmanship and in creating value in the market place.</p>
    </div>
    <div class="content content-contact">
      <p class="mat-title">If you are interested in one of our projects or are wanting more information about our services please reach out to our team.</p>
      <ul class="list-unstyled">
        <li><mat-icon>business</mat-icon>2925 Richmond Ave., Suite 1261, Houston, TX 77098</li>
        <li><mat-icon>phone</mat-icon><a href="tel:+17138042667">713-804-2667</a></li>
        <li><mat-icon>email</mat-icon><a href="mailto:info@constructionhch.com">info@constructionhch.com</a></li>
      </ul>
    </div>
  </section>
  <div class="container">
    <div class="page-header text-center">

    </div>
    <!-- <aoh-campaign-information></aoh-campaign-information> -->
  </div>
</div>
