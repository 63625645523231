import { ThankYouComponent } from './thank-you/thank-you.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalculatorComponent } from './calculator.component';
import { CalculatorFormComponent } from './calculator-form/calculator-form.component';


const routes: Routes = [
  {
    path: '',
    component: CalculatorComponent,
    data: { title: 'Apartment Questionnaire'},
    children: [
      { path: '', component: CalculatorFormComponent, data: {animation: 'FadeIn'}  },
      { path: 'thank-you', component: ThankYouComponent,  data: { title: 'Thank you!'}},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalculatorRoutingModule { }
