import { Router, ActivatedRoute } from '@angular/router';
import { CalculatorService } from './../calculator.service';
import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { LeadService } from '../lead.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatChipInputEvent } from '@angular/material/chips';
import * as moment from 'moment';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'aoh-campaign-information',
  templateUrl: './campaign-information.component.html',
  styleUrls: ['./campaign-information.component.scss']
})
export class CampaignInformationComponent implements OnInit {
  inProgress: boolean;
  form: FormGroup;

  minDate: Date;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  areaCtrl = new FormControl();
  filteredAreas: Observable<string[]>;
  areas: string[] = [];
  allAreas: string[] = [
    'Champions Forest',
    'Cypress',
    'Downtown',
    'Eado',
    'Energy Corridor',
    'Galleria',
    'Garden Oaks',
    'Jersey Village',
    'Katy',
    'Kirby',
    'Medical Center',
    'Memorial Area',
    'Midtown',
    'Rice Village',
    'River Oaks',
    'Spring Branch',
    'Spring',
    'Sugarland',
    'The Heights',
    'The Woodlands',
    'Tomball',
    'Uptown',
    'Washington',
    'Westchase',
    'Other'
  ];

  @ViewChild('areaInput') areaInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lead: LeadService,
    private calculator: CalculatorService,
    private analytics: AngularFireAnalytics) {
      this.filteredAreas   = this.areaCtrl.valueChanges.pipe(
        startWith(null),
        map((area: string | null) => area ? this._filter(area) : this.allAreas.slice()));
      this.minDate = new Date();
    }

    add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.areas.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.areaCtrl.setValue(null);
    }

    remove(fruit: string): void {
      const index = this.areas.indexOf(fruit);

      if (index >= 0) {
        this.areas.splice(index, 1);
      }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
      this.areas.push(event.option.viewValue);
      this.areaInput.nativeElement.value = '';
      this.areaCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();

      return this.allAreas.filter(area => area.toLowerCase().indexOf(filterValue) === 0);
    }

  ngOnInit(): void {
    this.form = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      mobile_number: new FormControl('', [
        Validators.required,
        Validators.pattern(/\(?\d{3}\)?-\d{3}-\d{4}|\d{10}/)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      deal: new FormGroup({
        amount: new FormControl('', [Validators.required]),
        expected_close: new FormControl('', [Validators.required]),
      }),
      custom_field: new FormGroup({
        cf_additional_information: new FormControl(''),
        cf_pets: new FormControl(''),
        cf_has_credit_issues:  new FormControl(''),
        cf_part_of_town:  new FormControl(''),
        cf_lead_type:  new FormControl('Apartment Lease'),
      }),
      lead_stage_id:  new FormControl(13000174958),
      // areaCtrl: new FormControl('')

    });
  }

  submit() {
    this.inProgress = true;
    const lead = this.form.getRawValue();
    const identifier = this.form.get('email').value;
    const mappedLead = {
      'First Name': lead.first_name,
      'Last Name': lead.last_name,
      'Emails': lead.email,
      'Mobile Number': `+1${lead.mobile_number}`,
      'Lead Type': lead.custom_field.cf_lead_type,
      'Part of Town': lead.custom_field.cf_part_of_town ? lead.custom_field.cf_part_of_town.join(';') : '',
      'Lead Stage': 'Filled out Survey',
      'deal': {
        'Expected Move-in Date':  moment(lead.deal.expected_close).format('YYYY-MM-DD'),
        'Monthly Budget': lead.deal.amount,
      },
      'Additional Information': lead.custom_field.cf_additional_information
    };


    window["freshsales"].identify(identifier, mappedLead);
    this.redirect();
    // "lead_stage_id": 13000174958,
    // "cf_lead_type": "Apartment Lease"
  }

  redirect() {
    this.inProgress = false;
    this.router.navigate(['/thank-you'], {
      relativeTo: this.route.parent
    });
  }

}
