export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDpIopVaO7QxQpJptVRMXJHamrA4NZPmWM',
    authDomain: 'hch-landing.firebaseapp.com',
    databaseURL: 'https://hch-landing.firebaseio.com',
    projectId: 'hch-landing',
    storageBucket: 'hch-landing.appspot.com',
    messagingSenderId: '295979913884',
    appId: '1:295979913884:web:7bc23dfa6eba8eea561359',
    measurementId: 'G-0L6THYWR27'
  },
  apiUrl: 'http://localhost:5001/aptsofhou-api/us-central1'
};
