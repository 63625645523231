import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'aoh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private analytics: AngularFireAnalytics
  ) { }

  ngOnInit(): void {
  }

  contact() {
    this.analytics.logEvent('contact_button_click');
    window.location.href = 'https://aptsofhou.typeform.com/to/Kjcx67?source=https://www.aohgo.com/crowdfunding-campaign-calculator';
  }

}
