import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  // needs to update when fundingGoal or services change
  change$: Subject<any> = new Subject();

  get onChange(): Observable<any> {
    return this.change$.asObservable();
  }

  VIDEO_FEE = 15000;
  private _fundingGoal: number;
  get fundingGoal(): number {
    return this._fundingGoal;
  }
  set fundingGoal(amount: number) {
    this._fundingGoal = amount;
    this.change$.next();
  }
  get videoFee(): number {
    return this.services.includePhotography ? this.VIDEO_FEE : 0;
  }
  adBudget: number;
  platformFee: number;
  paymentFee: number;
  agencyFee: number;
  estimatedTotal: number;

  services =  {
    includePhotography: false
  };

  constructor() {
    this._fundingGoal = 500000;
  }

  getTotal(goal?: number): number {
    goal = goal ? goal : this._fundingGoal;
    let total = 0;
    total += this.videoFee;
    total += this.getAdBudget();
    total += this.getPlatformFee();
    total += this.getPaymentFee();
    total += this.getAgencyFee();
    this.estimatedTotal = total;
    return total;
  }

  getAdBudget(): number {
    this.adBudget = this._fundingGoal * 0.1;
    return this.adBudget;
  }

  getPlatformFee(): number {
    this.platformFee = this._fundingGoal * 0.05;
    return this.platformFee;
  }

  getPaymentFee(): number {
    this.paymentFee = this._fundingGoal * 0.031;
    return this.paymentFee;
  }

  getAgencyFee(): number {
    // const vendorFees = this.platformFee + this.paymentFee;
    // this.agencyFee = 0.1 * (this._fundingGoal - vendorFees);
    // return this.agencyFee;
    return 0;
  }

  getFees() {
    let fees = [{
      name: 'Video/Photography',
      amount: this.videoFee,
      description: 'Estimated cost for a standard crowdfunding video and photo package including main product video, 30 teaser trailer, 2x social clips, lifestyle images, product images, GIFs'
    },
    {
      name: 'Proposed Ad Budget',
      amount: this.getAdBudget(),
      description: 'Our recommended spend is a minimum of 10% based on total funding goal.'
    },
    {
      name: 'Kickstarter Fee',
      amount: this.getPlatformFee(),
      description: 'The platform fee is a flat 5% based on total funds raised.'
    },
    {
      name: 'Payment Processing Fee',
      amount: this.getPaymentFee(),
      description: 'Payment processing fee is 3% + $0.20 per transaction.'
    },
    {
      name: 'HCH Group Fee',
      amount: this.getAgencyFee(),
      description: 'Our performance based commission rate on campaign funds raised.'
    }];
    return fees.filter(fee => fee.amount !== 0);
  }

  updateServices(name, value) {
    this.services[name] = value;
    this.change$.next();
  }

  // recalculate based on service changes
  // recalculate based on funding goal change
}
