<div class="page">
  <div class="container">
    <div class="page-header text-center">
      <p class="text-overline color--primary">Apartment Questionnaire
      </p>
      <mat-card>
        <h1 class="mat-display-1">Thank you!</h1>
        <p class="mat-subheading-1">An agent will contact you shortly.</p>
      </mat-card>
    </div>
  </div>
</div>
