import { Component, OnInit } from '@angular/core';
import { routeAnimations } from '../animations';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'aoh-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  animations: [
    routeAnimations
  ]
})
export class CalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

}
