import { CalculatorRoutingModule } from './calculator.routing.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CalculatorComponent } from './calculator.component';
import { CalculatorFormComponent } from './calculator-form/calculator-form.component';
import { CampaignInformationComponent } from './campaign-information/campaign-information.component';
import { ThankYouComponent } from './thank-you/thank-you.component';



@NgModule({
  declarations: [
    CalculatorComponent,
    CalculatorFormComponent,
    CampaignInformationComponent,
    ThankYouComponent,
  ],
  imports: [
    SharedModule,
    CalculatorRoutingModule
  ]
})
export class CalculatorModule { }
