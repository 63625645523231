import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  // {
  //   path: 'go/:token',
  //   component: LandingPageComponent,
  //   data: { title: 'Redirecting...' },
  //   resolve: {
  //     token: LandingPageResolverService
  //   }
  // },
  {
    path: '',
    loadChildren: () => import('./calculator/calculator.module').then(m => m.CalculatorModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
