import { CalculatorService } from './../calculator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'aoh-calculator-form',
  templateUrl: './calculator-form.component.html',
  styleUrls: ['./calculator-form.component.scss']
})
export class CalculatorFormComponent implements OnInit, OnDestroy {


  subscription: any;

  constructor(public calculator: CalculatorService) { }

  ngOnInit(): void {
    // need to remove

  }

  ngOnDestroy(): void {

  }

}
