<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-card class="card-form">
    <p class="text-overline">Your Contact Info</p>

    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput formControlName="first_name" placeholder="First Name" name="fname" required autocomplete="given-name" />
      <mat-error *ngIf="form.get('first_name').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="last_name" placeholder="Last Name" name="lname" autocomplete="family-name" required/>
      <mat-error *ngIf="form.get('last_name').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mobile Number</mat-label>
      <input
        matInput
        type="tel"
        autocomplete="tel"
        formControlName="mobile_number"
        name="phone"
        inputmode="numeric"
        mask="(000) 000-0000"
        prefix="+1"
        required/>
      <mat-error *ngIf="form.get('mobile_number').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" placeholder="Email" name="email" required/>
      <mat-error *ngIf="form.get('email').hasError('required')">
        This field is required
      </mat-error>
      <mat-error *ngIf="form.get('email').hasError('email')">
        Please enter a valid email
      </mat-error>
    </mat-form-field>

    <section formGroupName="deal">
      <p class="text-overline">What are you looking for?</p>

      <mat-form-field>
        <mat-label>Expected Move-in Date</mat-label>
        <input matInput [matDatepicker]="expectedClose" [min]="minDate" formControlName="expected_close" required>
        <mat-datepicker-toggle matSuffix [for]="expectedClose"></mat-datepicker-toggle>
        <mat-datepicker touchUi #expectedClose ></mat-datepicker>
        <mat-error *ngIf="form.get('deal.expected_close').hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Monthly Budget</mat-label>
        <input type="tel" matInput formControlName="amount" placeholder="$1000" inputmode="numeric" prefix="$" mask="separator"
        thousandSeparator="," required/>
        <mat-error *ngIf="form.get('deal.amount').hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </section>

    <section formGroupName="custom_field">
      <mat-form-field>
        <mat-label>Part of Town</mat-label>
        <mat-select formControlName="cf_part_of_town" multiple>
          <mat-option *ngFor="let area of allAreas" [value]="area">{{area}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-chip-list #chipList aria-label="Part of Town">
          <mat-chip
            *ngFor="let area of areas"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(area)">
            {{area}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Where do you want to live?"
            #areaInput
            formControlName="areaCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let area of filteredAreas | async" [value]="area">
            {{area}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->

      <mat-form-field floatLabel  ="always">
        <mat-label>Additional Information</mat-label>
        <textarea matInput formControlName="cf_additional_information" placeholder="I want high ceilings, I have a pet, etc."></textarea>
      </mat-form-field>
    </section>
    <button mat-flat-button color="primary" class="full" type="submit" [disabled]="form.invalid">Submit</button>
    <div class="spinner-overlay" *ngIf="inProgress">
      <mat-spinner class="spinner" diameter="44"></mat-spinner>
    </div>
  </mat-card>
</form>
